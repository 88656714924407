/*
 Description:  MIC Earn Business Home Page
 Author:       Hassan A. Adeola
 Author URI:   https://www.hassanadeola.com
 Author URI:   https://www.github.com/molary23
 Template:     ReactApp
 Version:      1.0.0
*/
/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin and set font
*/
* {
  margin: 0;
  font-family: "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* 
10. Use Smooth Scrolling
*/
html {
  scroll-behavior: smooth;
}

section {
  min-height: 700px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

input,
textarea {
  border-radius: 0px !important;
}

.btn {
  border-radius: 0px !important;
  height: 40px;
}

a {
  text-decoration: none;
}

textarea#contact-form-message {
  height: 120px;
}

.section-heading {
  color: rgba(41, 41, 41, 0.8);
  margin-bottom: 10px;
  text-align: center;
}
.section-heading h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
}
.section-heading h1:before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: -10px;
  left: calc(50% - 60px);
}
.section-heading h1:after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #05f2cb;
  bottom: -11px;
  left: calc(50% - 20px);
}
.section-heading h2 {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 100;
  margin-top: 20px;
  width: 0px;
}
.section-heading .animate {
  width: 100%;
  -webkit-animation: reveal 7s infinite;
          animation: reveal 7s infinite;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
}
@-webkit-keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 100%;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}
@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 100%;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}

.my-card {
  box-shadow: 0px 7px 15px 0px rgba(38, 59, 94, 0.2);
  border-radius: 5px;
  margin: 20px;
  transition: all 0.25s ease;
}
.my-card:hover {
  transform: scale(1.06);
}

.regular-btn {
  border: 1px solid #0077b6;
  color: #0077b6;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}
.regular-btn:hover {
  background: #0077b6;
  color: #fff;
}

.outline-btn {
  border: 1px solid #0077b6;
  background: #0077b6;
  color: #fff;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}
.outline-btn:hover {
  border: 1px solid #0077b6;
  background: #fff;
  color: #0077b6;
}

.navbar-brand {
  width: 2.5%;
}

.modal {
  background: rgba(0, 0, 0, 0.6);
}

.hidden {
  opacity: 0;
  transition: 2s;
  transform: translate(-100%);
  filter: blur(5px);
}

.show {
  opacity: 1;
  transform: translate(0);
  filter: blur(0);
}

.top-nav {
  background: transparent;
  padding-right: 10px;
  transition: background-color 500ms ease-in;
}
.top-nav .nav-link {
  cursor: pointer;
}
.top-nav .nav-link:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0px;
  background: transparent;
  transition: width 500ms ease, background-color 500ms ease;
}
.top-nav .nav-link:hover:after {
  width: 100%;
  background: #05f2cb;
}
.top-nav .active-nav:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 100%;
  background: #05f2cb;
}
.top-nav button.navbar-toggler {
  border: none;
}

.change-nav-bg {
  background: #97c6fc;
}
.change-nav-bg .nav-link {
  color: #fff !important;
}

.home-page {
  background: rgba(151, 198, 252, 0.2);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 400px;
  padding: 50px 0px 0px;
}
.home-page .intro-details {
  margin-top: 200px;
  text-transform: capitalize;
  margin-bottom: 80px;
  /* Animation */
  /* The typing effect */
  /* The typewriter cursor effect */
}
.home-page .intro-details .intro-name {
  font-size: 4rem;
  letter-spacing: -3.5px;
  font-weight: 600;
  color: rgba(41, 41, 41, 0.8);
}
.home-page .intro-details .info-role {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;
  margin-bottom: 20px;
}
.home-page .intro-details .info-job-role {
  color: #5ea1ed;
  font-family: monospace;
  overflow: hidden;
  border-right: 0.08em solid rgba(5, 242, 203, 0.8);
  white-space: nowrap;
  margin: 10px auto;
  letter-spacing: 0.15em;
  -webkit-animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
          animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  font-size: 1.35rem;
}
@-webkit-keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: rgba(5, 242, 203, 0.8);
  }
}
@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: rgba(5, 242, 203, 0.8);
  }
}
.home-page .intro-buttons .btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 10px;
  margin-bottom: 10px;
}
.home-page .intro-buttons .btn span {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.home-page .intro-image {
  margin-top: 80px;
}
.home-page .intro-image img {
  width: 70%;
  margin: 0px auto;
  filter: drop-shadow(1px 1px 3px rgb(37, 37, 37));
}

/* About Me */
.about {
  min-height: 400px;
  background: #fff;
  padding: 80px 0px;
}
.about .about-me {
  text-align: justify;
  margin: 0 auto;
}
.about .about-info {
  margin-bottom: 20px;
}
.about .about-image {
  width: 70%;
  margin: 0px auto;
  margin-bottom: 50px;
}
.about .about-image img {
  filter: drop-shadow(1px 1px 3px rgb(37, 37, 37));
}

/* Skills */
.skills {
  min-height: 400px;
  background: rgba(151, 198, 252, 0.7);
  padding: 80px 0px;
}
.skills .my-skills {
  text-align: center;
}
.skills .skill-card {
  border: 0.5px solid #fff;
  height: 200px;
  width: 200px;
  color: #0077b6;
  padding: 20px;
  background: #fff;
}
.skills .skill-card .skill-image {
  height: 50%;
}
.skills .skill-card .skill-image img {
  width: 40%;
  height: auto;
  margin: 0px auto;
  filter: opacity(0.8);
}
.skills .skill-card .skill-name {
  margin-top: 20px;
}
.skills .skill-card:hover {
  background: #0077b6;
  color: #fff;
}
.skills .skill-card:hover .skill-image img {
  filter: invert(1);
}

/* Contact */
.contact {
  min-height: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 50px 0px;
  background: rgba(151, 198, 252, 0.6);
}
.contact .contact-connect-info {
  margin-top: 50px;
}
.contact .contact-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  margin-bottom: 10px;
}
.contact .contact-details .contact-icon {
  border-radius: 40px;
  padding: 10px;
  background: rgba(151, 198, 252, 0.6);
  color: rgba(0, 119, 182, 0.8);
  width: 40px;
  height: 40px;
  text-align: center;
}
.contact .contact-details .contact-icon i {
  font-size: 20px;
}
.contact .contact-details .contact-info {
  margin-left: 20px;
  color: rgb(41, 41, 41);
  font-weight: 500;
}
.contact .contact-details .contact-info a {
  color: rgb(41, 41, 41);
  font-weight: 500;
}
.contact .contact-details .contact-info a:hover {
  text-decoration: underline;
}
.contact .contact-details .contact-location {
  text-transform: capitalize;
}
.contact .connect-info-card {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.contact .connect-info-card i {
  margin-right: 15px;
  color: rgba(0, 119, 182, 0.7);
}
.contact .connect-info-card i:hover {
  filter: invert(1);
}
.contact .btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid white;
  box-shadow: 0px 2px 1px 1px rgba(38, 59, 94, 0.2);
}
.contact .btn i {
  margin-left: 5px;
}

/* Service */
.services {
  min-height: 800px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 50px 0px;
  background: rgba(151, 198, 252, 0.4);
}
.services .service-card {
  min-height: 700px;
  width: 100%;
  padding: 50px 20px;
  background: #fff;
  color: rgba(41, 41, 41, 0.8);
}
.services .service-image,
.services .service-name {
  text-align: center;
}
.services .service-name {
  margin: 20px 0px;
}
.services .service-name h3 {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 1.25rem;
}
.services .service-content {
  text-align: justify;
  text-justify: inter-word;
}
.services .service-image i {
  border-radius: 40px;
  padding: 20px 0px;
  width: 80px;
  height: 80px;
  font-size: 40px;
  background: rgba(151, 198, 252, 0.6);
  color: rgba(0, 119, 182, 0.8);
}

/* Project */
.projects {
  min-height: 800px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 50px 0px;
  background: rgba(173, 232, 244, 0.2);
}
.projects .project-card {
  min-height: 400px;
  width: 100%;
  padding: 20px;
  background: #fff;
}
.projects .project-card .project-name {
  margin: 20px 0px;
  text-align: center;
}
.projects .project-card .project-name h3 {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 1.25rem;
}
.projects .project-card .project-type h5 {
  text-transform: capitalize;
  font-size: 1rem;
}
.projects .project-card .project-image-container {
  margin-top: 20px;
  position: relative;
}
.projects .project-card .project-image-container .project-image {
  display: block;
  width: 100%;
  height: auto;
}
.projects .project-card .project-image-container .overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background: #0077b6;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.5s ease;
}
.projects .project-card .project-image-container:hover .overlay {
  width: 100%;
  left: 0;
}
.projects .project-card .project-image-container .text {
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.projects .project-card .project-image-container .text a {
  font-size: 1rem;
  color: #fff;
}
.projects .project-card .project-image-container .text .project-store-link a {
  display: block;
}
.projects .project-card .project-image-container .text .project-store-link a .store-name {
  text-transform: capitalize;
}
.projects .project-card .project-details {
  display: flex;
  height: 60px;
  margin-bottom: 20px;
}
.projects .project-card .project-details .project-stack {
  margin-left: 10px;
}
.projects .project-card .project-details .project-title label {
  font-weight: 600;
}
.projects .project-card .project-details .project-category {
  margin-left: 10px;
}
.projects .project-card .project-details .project-category p {
  font-weight: 600;
  text-transform: capitalize;
}
.projects .view-more {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.projects .view-more .btn {
  width: 20%;
}

/* Footer */
.footer-nav {
  background: rgba(0, 119, 182, 0.7) !important;
  color: #fff;
}
.footer-nav .footer-title {
  text-transform: capitalize;
  margin: 10px 0px;
}
.footer-nav .footer-title span {
  font-weight: 500;
}

/* Loading Progress */
.loader {
  text-align: center;
  padding-top: 300px;
  min-height: 700px;
}
.loader h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.loader .loading-bar {
  width: 20%;
  margin: 20px auto 0px;
}
.loader .loading-bar .progress {
  height: 5px;
  border-radius: 0px;
  inset: 0;
}
.loader .loading-bar .progress-bar {
  position: relative;
  -webkit-animation: progress-indeterminate 3s linear infinite;
  animation: progress-indeterminate 3s linear infinite;
}
@-webkit-keyframes progress-indeterminate {
  from {
    left: -20%;
    width: 20%;
  }
  to {
    left: 100%;
    width: 20%;
  }
}
@keyframes progress-indeterminate {
  from {
    left: -20%;
    width: 20%;
  }
  to {
    left: 100%;
    width: 20%;
  }
}
.loader .loading-title {
  font-family: monospace;
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 100;
  color: #5ea1ed;
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .home-page .intro-details {
    margin-top: 160px;
  }
  .home-page .intro-details .intro-name {
    font-size: 2.4rem;
  }
  .home-page .intro-details .info-job-role {
    font-size: 1.4rem;
  }
  .home-page .intro-details .intro-buttons .btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .home-page .intro-details .intro-buttons .btn i {
    margin-left: 10px;
  }
  .home-page .intro-image {
    margin-top: 50px;
  }
  .contact .contact-connect-info {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .skill-card {
    width: 150px;
  }
}
@media only screen and (max-width: 479px) {
  .navbar-brand {
    width: 10%;
  }
  .home-page {
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  .home-page .intro-details {
    margin-top: 80px;
    padding-left: 20px;
    padding-bottom: 50px;
  }
  .home-page .intro-details .intro-name {
    font-size: 2.5rem;
  }
  .home-page .intro-details .info-job-role {
    font-size: 1.2rem;
  }
  .home-page .intro-details .intro-buttons .btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .home-page .intro-details .intro-buttons .btn i {
    margin-left: 10px;
  }
  .skills .skill-card {
    width: 130px;
    height: 130px;
    padding: 20px 10px;
  }
  .skills .skill-name h3 {
    font-size: 1.25rem;
  }
  .projects .project-card {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .services .service-card {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .footer-title {
    font-size: 0.9rem;
  }
  .navbar-collapse {
    background: #97c6fc;
    padding: 0px 10px;
  }
  .navbar-collapse:active {
    border: none;
  }
  .contact .contact-connect-info {
    padding-left: 20px;
  }
  .about {
    width: 90%;
    margin: 0px auto;
  }
  .about .about-image {
    margin-bottom: 50px;
  }
  .section-heading h2 {
    font-size: 1rem;
  }
  .loading-section {
    padding: 0px 20px;
  }
  .loading-section h1 {
    font-size: 2.5rem;
  }
}/*# sourceMappingURL=style.css.map */