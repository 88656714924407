@media only screen and (min-width: 768px) and (max-width: 959px) {
  .home-page {
    .intro-details {
      margin-top: nth($spacing, 5) * 2;
      .intro-name {
        font-size: 2.4rem;
      }
      .info-job-role {
        font-size: 1.4rem;
      }
      .intro-buttons .btn {
        width: fit-content;
        i {
          margin-left: nth($spacing, 2);
        }
      }
    }
    .intro-image {
      margin-top: nth($spacing, 4);
    }
  }

  .contact {
    .contact-connect-info {
      padding-left: nth($spacing, 3);
    }
  }
}

@media only screen and (max-width: 767px) {
  .skill-card {
    width: nth($box, 1) - 50px;
  }
}

@media only screen and (max-width: 479px) {
  .navbar-brand {
    width: 10%;
  }
  .home-page {
    min-height: fit-content;
    .intro-details {
      margin-top: nth($spacing, 5);
      padding-left: nth($spacing, 3);
      padding-bottom: nth($spacing, 4);
      .intro-name {
        font-size: 2.5rem;
      }
      .info-job-role {
        font-size: 1.2rem;
      }
      .intro-buttons .btn {
        width: fit-content;
        i {
          margin-left: nth($spacing, 2);
        }
      }
    }
  }
  .skills {
    .skill-card {
      width: nth($box, 1) - 70px;
      height: nth($box, 1) - 70px;
      padding: nth($spacing, 3) nth($spacing, 2);
    }
    .skill-name h3 {
      font-size: 1.25rem;
    }
  }

  .projects {
    .project-card {
      width: fit-content;
    }
  }
  .services {
    .service-card {
      width: fit-content;
    }
  }
  .footer-title {
    font-size: 0.9rem;
  }
  .navbar-collapse {
    background: rgba(nth($color, 2), 1);
    padding: 0px nth($spacing, 2);
    &:active {
      border: none;
    }
  }
  .contact {
    .contact-connect-info {
      padding-left: nth($spacing, 3);
    }
  }
  .about {
    width: 90%;
    margin: nth($spacing, 1) auto;
    .about-image {
      margin-bottom: nth($spacing, 4);
    }
  }
  .section-heading {
    h2 {
      font-size: 1rem;
    }
  }
  .loading-section {
    padding: 0px 20px;
    h1 {
      font-size: 2.5rem;
    }
  }
}
