/*
 Description:  MIC Earn Business Home Page
 Author:       Hassan A. Adeola
 Author URI:   https://www.hassanadeola.com
 Author URI:   https://www.github.com/molary23
 Template:     ReactApp
 Version:      1.0.0
*/

@import "constants.scss";

/*
  1. Use a more-intuitive box-sizing model.
*/
* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}
/*
  2. Remove default margin and set font
*/
* {
  margin: 0;
  font-family: "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* 
10. Use Smooth Scrolling
*/
html {
  scroll-behavior: smooth;
}

section {
  min-height: 700px;
  height: fit-content;
}

input,
textarea {
  border-radius: 0px !important;
}

.btn {
  border-radius: 0px !important;
  height: 40px;
}

a {
  text-decoration: none;
}

textarea#contact-form-message {
  height: 120px;
}

.section-heading {
  color: rgba(nth($color, 7), 0.8);
  margin-bottom: nth($spacing, 2);
  text-align: center;
  h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: nth($spacing, 3);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 120px;
      height: 1px;
      background: #ddd;
      bottom: -10px;
      left: calc(50% - 60px);
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 40px;
      height: 3px;
      background: rgba(nth($color, 8), 1);
      bottom: -11px;
      left: calc(50% - 20px);
    }
  }

  h2 {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 100;
    margin-top: nth($spacing, 3);
    width: 0px;
  }
  .animate {
    width: 100%;
    animation: reveal 7s infinite;
    animation-iteration-count: 2;

    @keyframes reveal {
      0% {
        opacity: 0;
        width: 0px;
      }
      20% {
        opacity: 1;
        width: 0px;
      }
      30% {
        width: 100%;
      }
      80% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        width: 100%;
      }
    }
  }
}

.my-card {
  box-shadow: 0px 7px 15px 0px rgba(38, 59, 94, 0.2);
  border-radius: calc(nth($spacing, 2) / 2);
  margin: nth($spacing, 3);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  &:hover {
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
  }
}

.regular-btn {
  border: 1px solid nth($color, 1);
  color: nth($color, 1);
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  &:hover {
    background: nth($color, 1);
    color: #fff;
  }
}
.outline-btn {
  border: 1px solid nth($color, 1);
  background: nth($color, 1);
  color: #fff;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  &:hover {
    border: 1px solid nth($color, 1);
    background: #fff;
    color: nth($color, 1);
  }
}

.navbar-brand {
  width: 2.5%;
}

.modal {
  background: rgba($color: #000, $alpha: 0.6);
}

.hidden {
  opacity: 0;
  transition: 2s;
  transform: translate(-100%);
  filter: blur(5px);
}

.show {
  opacity: 1;
  transform: translate(0);
  filter: blur(0);
}
