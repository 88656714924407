@import "default.scss";

.top-nav {
  background: transparent;
  padding-right: nth($spacing, 2);
  transition: background-color 500ms ease-in;
  .nav-link {
    cursor: pointer;
    &:after {
      content: "";
      display: block;
      margin: auto;
      height: 3px;
      width: 0px;
      background: transparent;
      transition: width 500ms ease, background-color 500ms ease;
    }
    &:hover:after {
      width: 100%;
      background: rgba(nth($color, 8), 1);
    }
  }
  .active-nav {
    &:after {
      content: "";
      display: block;
      margin: auto;
      height: 3px;
      width: 100%;
      background: rgba(nth($color, 8), 1);
    }
  }

  button.navbar-toggler {
    border: none;
  }
}
.change-nav-bg {
  background: rgba(nth($color, 2), 1);
  .nav-link {
    color: nth($color, 5) !important;
  }
}

.home-page {
  background: rgba(nth($color, 2), 0.2);
  height: fit-content;
  min-height: nth($box, 2);
  padding: nth($spacing, 4) nth($spacing, 1) 0px;

  .intro-details {
    margin-top: nth($spacing, 4) * 4;
    text-transform: capitalize;
    margin-bottom: nth($spacing, 5);
    .intro-name {
      font-size: 4rem;
      letter-spacing: -3.5px;
      font-weight: 600;
      color: rgba(nth($color, 7), 0.8);
    }

    .info-role {
      width: fit-content;
      height: 30px;
      margin-bottom: nth($spacing, 3);
    }

    /* Animation */
    .info-job-role {
      color: rgba(nth($color, 6), 1);
      font-family: monospace;
      overflow: hidden;
      border-right: 0.08em solid rgba(nth($color, 8), 0.8);
      white-space: nowrap;
      margin: nth($spacing, 2) auto;
      letter-spacing: 0.15em;
      animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
      font-size: 1.35rem;
    }

    /* The typing effect */
    @keyframes typing {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }

    /* The typewriter cursor effect */
    @keyframes blink-caret {
      from,
      to {
        border-color: transparent;
      }
      50% {
        border-color: rgba(nth($color, 8), 0.8);
      }
    }
  }

  .intro-buttons .btn {
    width: fit-content;
    margin-right: nth($spacing, 2);
    margin-bottom: nth($spacing, 2);
    span {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  .intro-image {
    margin-top: nth($spacing, 5);

    img {
      width: 70%;
      margin: 0px auto;
      filter: drop-shadow(1px 1px 3px rgb(37, 37, 37));
    }
  }
}

/* About Me */
.about {
  min-height: nth($box, 2);
  background: nth($color, 5);
  padding: nth($spacing, 5) 0px;

  .about-me {
    text-align: justify;
    margin: 0 auto;
  }
  .about-info {
    margin-bottom: nth($spacing, 3);
  }
  .about-image {
    width: 70%;
    margin: 0px auto;
    margin-bottom: nth($spacing, 4);
    img {
      filter: drop-shadow(1px 1px 3px rgb(37, 37, 37));
    }
  }
}

/* Skills */
.skills {
  min-height: nth($box, 2);
  background: rgba(nth($color, 2), 0.7);
  padding: nth($spacing, 5) 0px;

  .my-skills {
    text-align: center;
  }

  .skill-card {
    border: 0.5px solid nth($color, 5);
    height: nth($box, 1);
    width: nth($box, 1);
    color: rgba(nth($color, 1), 1);
    padding: nth($spacing, 3);
    background: nth($color, 5);
    .skill-image {
      height: 50%;

      img {
        width: 40%;
        height: auto;
        margin: 0px auto;
        filter: opacity(0.8);
      }
    }
    .skill-name {
      margin-top: nth($spacing, 3);
    }

    &:hover {
      background: rgba(nth($color, 1), 1);
      color: nth($color, 5);
      .skill-image {
        img {
          filter: invert(1);
        }
      }
    }
  }
}

/* Contact */
.contact {
  min-height: nth($box, 2);
  height: fit-content;
  padding: nth($spacing, 4) 0px;
  background: rgba(nth($color, 2), 0.6);

  .contact-connect-info {
    margin-top: nth($spacing, 4);
  }

  .contact-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    margin-bottom: nth($spacing, 2);
    .contact-icon {
      border-radius: 40px;
      padding: nth($spacing, 2);
      background: rgba(nth($color, 2), 0.6);
      color: rgba(nth($color, 1), 0.8);
      width: 40px;
      height: 40px;
      text-align: center;
      i {
        font-size: 20px;
      }
    }
    .contact-info {
      margin-left: nth($spacing, 3);
      color: nth($color, 7);
      font-weight: 500;
      a {
        color: nth($color, 7);
        font-weight: 500;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .contact-location {
      text-transform: capitalize;
    }
  }

  .connect-info-card {
    margin-top: nth($spacing, 4);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
      margin-right: 15px;
      color: rgba(nth($color, 1), 0.7);
      &:hover {
        filter: invert(1);
      }
    }
  }

  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(nth($color, 5), 1);
    box-shadow: 0px 2px 1px 1px rgba(38, 59, 94, 0.2);

    i {
      margin-left: calc(nth($spacing, 2) / 2);
    }
  }
}
/* Service */
.services {
  min-height: nth($box, 4);
  height: fit-content;
  padding: nth($spacing, 4) 0px;
  background: rgba(nth($color, 2), 0.4);

  .service-card {
    min-height: nth($box, 4) - 100px;
    width: 100%;
    padding: nth($spacing, 4) nth($spacing, 3);
    background: nth($color, 5);
    color: rgba(nth($color, 7), 0.8);
  }

  .service-image,
  .service-name {
    text-align: center;
  }

  .service-name {
    margin: nth($spacing, 3) 0px;
    h3 {
      font-weight: 600;
      text-transform: capitalize;
      font-size: 1.25rem;
    }
  }
  .service-content {
    text-align: justify;
    text-justify: inter-word;
  }

  .service-image {
    i {
      border-radius: 40px;
      padding: nth($spacing, 3) 0px;
      width: 80px;
      height: 80px;
      font-size: 40px;
      background: rgba(nth($color, 2), 0.6);
      color: rgba(nth($color, 1), 0.8);
    }
  }
}

/* Project */
.projects {
  min-height: nth($box, 4);
  height: fit-content;
  padding: nth($spacing, 4) 0px;
  background: rgba(nth($color, 3), 0.2);

  .project-card {
    min-height: nth($box, 2);
    width: 100%;
    padding: nth($spacing, 3);
    background: nth($color, 5);

    .project-name {
      margin: nth($spacing, 3) 0px;
      text-align: center;
      h3 {
        font-weight: 600;
        text-transform: capitalize;
        font-size: 1.25rem;
      }
    }
    .project-type h5 {
      text-transform: capitalize;
      font-size: 1rem;
    }
    .project-image-container {
      margin-top: nth($spacing, 3);
      position: relative;

      .project-image {
        display: block;
        width: 100%;
        height: auto;
      }

      .overlay {
        position: absolute;
        bottom: 0;
        left: 100%;
        right: 0;
        background: rgba(nth($color, 1), 1);

        overflow: hidden;
        width: 0;
        height: 100%;
        transition: 0.5s ease;
      }

      &:hover .overlay {
        width: 100%;
        left: 0;
      }

      .text {
        color: nth($color, 5);
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        white-space: nowrap;

        a {
          font-size: 1rem;
          color: nth($color, 5);
        }
        .project-store-link a {
          display: block;
          .store-name {
            text-transform: capitalize;
          }
        }
      }
    }
    .project-details {
      display: flex;
      height: 60px;
      margin-bottom: nth($spacing, 3);
      .project-stack {
        margin-left: nth($spacing, 2);
      }
      .project-title label {
        font-weight: 600;
      }
      .project-category {
        margin-left: nth($spacing, 2);
        p {
          font-weight: 600;
          text-transform: capitalize;
        }
      }
    }
  }

  .view-more {
    display: flex;
    justify-content: center;
    margin-top: nth($spacing, 4);
    .btn {
      width: 20%;
    }
  }
}

/* Footer */
.footer-nav {
  background: rgba(nth($color, 1), 0.7) !important;
  color: #fff;

  .footer-title {
    text-transform: capitalize;
    margin: 10px 0px;
    span {
      font-weight: 500;
    }
  }
}

/* Loading Progress */
.loader {
  text-align: center;
  padding-top: nth($box, 2) - 100px;
  min-height: 700px;

  h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: nth($spacing, 3);
  }

  .loading-bar {
    width: 20%;
    margin: nth($spacing, 3) auto nth($spacing, 1);

    .progress {
      height: 5px;
      border-radius: 0px;
      inset: 0;
    }

    .progress-bar {
      position: relative;
      -webkit-animation: progress-indeterminate 3s linear infinite;
      animation: progress-indeterminate 3s linear infinite;
    }

    @-webkit-keyframes progress-indeterminate {
      from {
        left: -20%;
        width: 20%;
      }
      to {
        left: 100%;
        width: 20%;
      }
    }

    @keyframes progress-indeterminate {
      from {
        left: -20%;
        width: 20%;
      }
      to {
        left: 100%;
        width: 20%;
      }
    }
  }
  .loading-title {
    font-family: monospace;
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 100;
    color: rgba(nth($color, 6), 1);
  }
}

@import "responsive.scss";
